// Translate
// Migrated
<template lang="pug">
CalendarSearchBarButton(
  id="charterButtonGuests",
  ref="charterButtonGuests",
  :active="active",
  :loading="loading"
  :slim="slim"
  @click="$emit('setActive')"
)
  template(#icon)
    fa(:icon="icons.faUserFriends")
  template(#label)
    span(data-i18n="charterSearchGuests") {{ $t('charterSearchGuests') }}
  template(#content)
    span(
      v-if="kids === 0"
      data-i18n="chooseRoomTypeAdults"
    ) {{ $t('chooseRoomTypeAdults', adults) }}
      small.d-block(
        v-if="!selectChildAges"
        data-i18n="charterSearchGuestNumberRooms"
      ) {{ $t('charterSearchGuestNumberRooms', rooms) }}
    span(v-else)
      i18n-t(
        data-i18n="charterSearchGuestNumber"
        keypath="charterSearchGuestNumber"
        tag="span"
      )
        template(#adults)
          span(data-i18n="chooseRoomTypeAdults") {{ $t('chooseRoomTypeAdults', adults) }}
        template(#children)
          span(data-i18n="chooseRoomTypeChildren") {{ $t('chooseRoomTypeChildren', kids) }}
      small.d-block.text-medium-gray(
        v-if="!selectChildAges"
        data-i18n="charterSearchGuestNumberRooms"
      ) {{ $t('charterSearchGuestNumberRooms', rooms) }}
  template(#append)
    .charter-search__btn-title.text-error.charter-search__error__childs-ages.blink.blink-title.z-index-1000.pt-0.pt-sm-1(
      v-if="selectChildAges && !(windowWidth <= 992 && active)"
      data-i18n="charterSelectChildAges"
    ) {{ $t('charterSelectChildAges') }}
</template>

<script>
import { faUserFriends } from '@fortawesome/pro-duotone-svg-icons'
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    active: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    slim: {
      type: Boolean,
      default: false
    },

    selectChildAges: {
      type: Boolean,
      default: false
    },
  },

  emits: ['setActive'],

  data () {
    return {
      icons: {
        faUserFriends,
      },

      windowWidth: 0
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, {
      peoplePerRoom: 'peoplePerRoom'
    }),

    kids () {
      return this.peoplePerRoom.children
    },

    adults () {
      return this.peoplePerRoom.adults
    },

    rooms () {
      return this.peoplePerRoom.rooms
    }
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)

    this.windowWidth = window.innerWidth
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    resizeListener () {
      this.windowWidth = window.innerWidth
    },
  }
})
</script>
